<template>
  <component :is="comp" />
</template>
<style lang="scss">
  @import '../assets/styles/components/img';
</style>
<script>
import rotate1 from '@/components/img/ImgRotate1'
import rotate2 from '@/components/img/ImgRotate2'
import rotate3 from '@/components/img/ImgRotate3'
import parallax1 from '@/components/img/ImgParallax1'
import parallax2 from '@/components/img/ImgParallax2'
import parallax3 from '@/components/img/ImgParallax3'
import parallax4 from '@/components/img/ImgParallax4'
import parallax5 from '@/components/img/ImgParallax5'
import parallax6 from '@/components/img/ImgParallax6'
import parallax7 from '@/components/img/ImgParallax7'
import parallax8 from '@/components/img/ImgParallax8'
import parallax9 from '@/components/img/ImgParallax9'
import line1 from '@/components/img/ImgLine1'
import line2 from '@/components/img/ImgLine2'
import line3 from '@/components/img/ImgLine3'
export default {
  components: {
    rotate1, rotate2, rotate3, parallax1, parallax2, parallax3, parallax4, parallax5, parallax6, parallax7, parallax8, parallax9, line1, line2, line3
  },
  props: {
    type: {
      required: true,
      type: String
    }
  },
  computed: {
    comp () {
      return this.type
    }
  }
}
</script>
